<script>
export default {
  mounted() {
    this.getConfigInfo();
  },
  methods: {
    getConfigInfo() {
      console.log(window.location.href.split("#")[0]);
      this.$http
        .post("/sign", {
          url: encodeURIComponent(window.location.href.split("#")[0]),
        })
        .then((res) => {
          console.log(res.data);
          window.wx.config({
            ...res.data,
            jsApiList: ["updateAppMessageShareData", "onMenuShareQQ"],
            debug: false,
          });
          this.handleShare();
        })
        .catch(() => {
          alert("failed");
        });
    },
    handleShare() {
      // let url = window.location.origin + '/?source=7#/'
      let url = "http://192.168.2.101:9780/?source=7#/";
      console.log(url);

      let urlImg = "https://static-1.askdr.cn/genetic/share.png";
      console.log(urlImg);
      // let imgUrl = require('/img/imgUrl.png')

      // var urlAll = window.location.href
      // var hosts = urlAll.split('index.html')[0] //获取域名加打包文件夹名称
      // console.log(hosts);

      // var shareImg = hosts.concat(imgUrl)
      // console.log(shareImg)
      window.wx.onMenuShareQQ({
        title: "基因检测用药免费查询", // 分享标题
        desc: "准确查询到适合您的推荐用药、对应价格、是否纳入医保、厂家等药品信息。", // 分享描述
        link: url, // 分享链接
        imgUrl: urlImg, // 分享图标
        success: function () {
          // 用户确认分享后执行的回调函数
          console.log("successQQ");
        },
      });

      window.wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        window.wx.updateAppMessageShareData({
          title: "基因检测用药免费查询", // 分享标题
          desc: "准确查询到适合您的推荐用药、对应价格、是否纳入医保、厂家等药品信息。", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: urlImg, // 分享图标
          success: function () {
            // 设置成功
            console.log("success");
          },
        });
      });
    },
  },
};
</script>
