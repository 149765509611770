import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/illness",
    name: "Illness",
    component: () =>
      import(/* webpackChunkName: "Illness" */ "../views/illness"),
  },
  {
    path: "/result",
    name: "Result",
    component: () => import(/* webpackChunkName: "Result" */ "../views/result"),
  },
  {
    path: "/download",
    name: "Download",
    component: () =>
      import(
        /* webpackChunkName: "Download" */ "../views/result/blocks/download"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
