import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import axios from "axios";
import http from "./http/http";
import "./components";
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// Vue.use(vConsole);
Vue.use(vant);
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
