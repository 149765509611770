//request.js
import axios from "axios";
import qs from "qs";
// import { Loading, Message } from "element-ui";

let apiUrl = process.env.VUE_APP_BASE_API;
// console.log(apiUrl, process.env, "apiUrl");
const service = axios.create({
  baseURL: apiUrl, // api的base_url
  timeout: 15000, // 请求超时时间
});

service.defaults.withCredentials = true;
service.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
service.defaults.timeout = 300000;

// let loading,
// let dateTime = 0;
service.interceptors.request.use(
  (config) => {
    // dateTime = new Date().getTime();
    // loading = Loading.service({
    //   lock: true,
    //   text: "Loading",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.6)",
    // });
    filterParams(config.data);
    filterParams(config.params);
    let token = localStorage.getItem("token");
    config.headers["Authorization"] = token;

    if (config.method === "post") {
      if (config.type !== "json") {
        config.data = qs.stringify({ ...config.data });
      }
      // if (
      //   !config.headers["Content-Type"] ||
      //   config.headers["Content-Type"] == "application/x-www-form-urlencoded"
      // ) {
      //   config.data = qs.stringify({ ...config.data });
      // }
    } else {
      config.params = { ...config.params };
    }
    return config;
  },
  (error) => {
    // getDateTimeCompare(dateTime);
    // loading.close();
    return Promise.reject(error);
  }
);

// http response 服务器响应拦截器，
// 这里拦截401错误，并重新跳入登页重新获取token
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    // loadingClose === false 表示不启用close
    // response.config.loadingClose !== false && getDateTimeCompare(dateTime);
    if (response.data.code === 305) {
      localStorage.removeItem("token");
      // alert(response.data.msg);
      // Message.error(response.data.msg);
      setTimeout(() => {
        if (location.href.indexOf("/login?") < 0) {
          // 防止重复定向问题
          location.href =
            location.pathname +
            "#/login?redirect=" +
            location.hash.replace("#", "");
        }
      }, 1500);
      return;
    }

    return Promise.resolve(response.data);
  },
  (error) => {
    // loading.close();
    // getDateTimeCompare(dateTime);
    return Promise.reject(error.response && error.response.data);
  }
);

let filterParams = function (req) {
  if (req) {
    Object.keys(req).forEach(function (k) {
      if (req[k] === null || req[k] === "" || req[k] === undefined) {
        delete req[k];
      }
    });
  }
  return req;
};
service.postJson = function (url, _data) {
  let headers = { "Content-Type": "application/json" };
  let token = localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = token;
  }
  return axios.request({
    baseURL: apiUrl,
    headers: headers,
    transformRequest: [
      function (data) {
        data = JSON.stringify(data);
        return data;
      },
    ],
    params: {},
    url: url,
    method: "post",
    data: _data,
    postJson: true,
  });
};

/**
 * @method:
 * @param {*} time
 * @return {*}
 * @desc:获取时间进行对比
 */
// function getDateTimeCompare(time, loading) {
//   let dateTime = new Date().getTime() - time;
//   if (dateTime > 500) {
//     loading.close();
//   } else {
//     setTimeout(() => {
//       loading.close();
//     }, 500 - dateTime);
//   }
// }

export default service;
export { apiUrl };
export { filterParams };
