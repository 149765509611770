<template>
  <div>
    <div class="logo">
      <img src="../../assets/logo.png" />
    </div>
    <div class="identity">
      <van-field name="radio" label="您的身份">
        <template #input>
          <van-radio-group
            v-model="params.role"
            direction="horizontal"
            @change="changeRadio"
          >
            <van-radio name="1">本人</van-radio>
            <van-radio name="0">亲属</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </div>
    <div class="divider">
      <van-divider />
    </div>
    <div class="sickness">
      <div class="sicknessOne" @click="goIllness()">
        <h3>疾病类型</h3>
        <input v-model="illness" placeholder="选择疾病" readonly="readonly" />
      </div>
      <div class="sicknessTwo" @click="goIllness()">
        <h3>突变位点</h3>
        <input
          v-model="mutationSite"
          placeholder="选择位点"
          readonly="readonly"
        />
      </div>
    </div>
    <div class="info">
      <p>1.本查询工具由易问医App团队开发，完全免费。</p>
      <p>
        2.准确查询到适合您的推荐用药、对应价格、是否纳入医保、厂家等药品信息。
      </p>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        margin-top: 80px;
        padding-bottom: 20px;
      "
    >
      <div class="footers" @click="check()">立即查询</div>
    </div>
    <share />
  </div>
</template>
<script>
import Share from "../../components/wechat/share.vue";
export default {
  components: { Share },
  data() {
    return {
      dataList: null,
      mutationSite: [],
      illness: "",
      params: {
        role: "1",
        id: null,
        target: [],
        uid: "",
        source: "",
      },
      activeIndex: null,
    };
  },
  methods: {
    changeRadio() {
      console.log(this.params.role);
      this.$store.commit("setRelation", this.params.role);
    },
    getUid() {
      this.$http.post("/getUid").then((res) => {
        console.log(res.data);
        this.setSource(res.data);
        this.$store.commit("setUid", res.data);
      });
    },
    setSource(uid) {
      let params = { uid: uid, source: this.$store.getters.source };

      if (this.$route.query.patientName) {
        params.patientName = this.$route.query.patientName;
      }

      if (this.$route.query.patientId) {
        params.patientId = this.$route.query.patientId;
      }

      if (this.$route.query.gender) {
        params.gender = this.$route.query.gender;
      }

      if (this.$route.query.age) {
        params.age = this.$route.query.age;
      }

      if (this.$route.query.patientPhone) {
        params.patientPhone = this.$route.query.patientPhone;
      }

      this.$http.post("/setSource", params).then((res) => {
        console.log(res);
      });
    },
    check() {
      console.log(this.illness);

      if (!this.illness) {
        // console.log(this.illness)
        this.$toast("请选择疾病类型");
      }

      if (!this.mutationSite || this.mutationSite.length === 0) {
        this.$toast("请选择突变位点");
      }

      console.log(this.$store.getters.source);

      if (this.mutationSite && this.illness) {
        this.params.uid = this.$store.getters.uid;
        this.params.source = this.$store.getters.source;
        this.$http
          .post("/commit", this.params, { type: "json" })
          .then((res) => {
            console.log(res.code);
            if (res.code === 0) {
              console.log(res.msg);
              res.data.targetEntityList?.forEach((el, index) => {
                let num = index + 1;
                this.$set(el, "activeName", num++);
              });
              this.$store.commit("setCommitList", res.data);
              this.$router.push({ name: "Result" });
            }
          });
      }
    },
    goIllness() {
      this.$router.push({
        name: "Illness",
      });
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      console.log(query);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },

  mounted() {
    this.illness = this.$store.getters.illness;
    this.params.target = this.$store.getters.targetId;
    this.params.id = this.$store.getters.illnessId;
    this.mutationSite = this.$store.getters.targetList;
    this.activeIndex = this.$store.getters.activeIndex;
    const { patientPhone } = this.$route.query;

    console.log("this.$route", this.$route);
    if (this.$route.query.source) {
      // console.log(this.$route.query.source)
      this.$store.commit("setSource", this.$route.query.source);
      // console.log(this.$store.getters.source)
    } else if (window.location.search) {
      let source = this.getQueryVariable("source");
      this.$store.commit("setSource", source);
      // console.log(source)
    } else {
      this.$store.commit("setBeginSource", window.location.hash.split("=")[1]);
      // console.log('setBeginSource', window.location.hash.split('=')[1])
    }

    if (patientPhone && patientPhone !== "null") {
      this.$store.commit("setPhone", this.$route.query.patientPhone);
    }
    // if (!this.$route.query.uidData) {
    //   this.getUid()
    // }
    // if (this.$store.getters.uidData<0) {
    //   console.log('getUid')
    //   this.getUid()
    // }

    this.getUid();
    if (this.$store.getters.relation) {
      // console.log(this.$route.query.relation)
      this.params.role = this.$store.getters.relation;
    }
  },
};
</script>
<style lang="scss" scoped>
.logo {
  height: 58px;
  background-image: linear-gradient(to right, #e7e5ff, #95c2ff);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 230px;
    height: 35px;
  }
}

.identity {
  margin: 15px 0px 0px 9px;
}

::v-deep .van-field__label {
  font-size: 17px;
  font-weight: bold;
  color: #000;
}

::v-deep .van-radio__label {
  font-size: 17px;
}

.divider {
  margin: 15px 15px 25px 15px;
}

.sickness {
  margin: 25px;
  .sicknessOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 17px;
      color: #000;
    }
    input {
      display: inline-block;
      height: 45px;
      width: 220px;
      outline: none;
      border: none;
      background: #f6f6f6;
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ::placeholder {
      //通过这个设置
      color: #aaa;
    }
  }
  .sicknessTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px 50px 0px;
    h3 {
      font-size: 17px;
      color: #000;
    }
    input {
      display: inline-block;
      height: 45px;
      width: 220px;
      outline: none;
      border: none;
      background: #f6f6f6;
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ::placeholder {
      //通过这个设置
      color: #aaa;
    }
  }
}

.info {
  margin: 0px 25px;
  p {
    font-size: 14px;
    color: #999;
    text-align: left;
  }
}

.footers {
  width: 295px;
  height: 45px;
  line-height: 45px;
  border-radius: 45px;
  background-image: linear-gradient(to right, #4f74ff, #0067f2);
  //position: fixed;
  //left: 50%;
  //bottom: 64px;
  //margin-left: -148px;
  color: #fff;
  font-size: 17px;
}
</style>
