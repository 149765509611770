import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    targetId: null,
    targetList: null,
    illness: "",
    illnessId: "",
    activeIndex: 0,
    uid: "",
    commitList: null,
    downloadImg: "",
    source: "",
    relation: "",
    beginSource: "",
    uidData: -1,
    phone: "",
  },
  mutations: {
    setTargetId(state, data) {
      state.targetId = data;
    },
    setTargetList(state, data) {
      state.targetList = data;
    },
    setIllness(state, data) {
      state.illness = data;
    },
    setIllnessId(state, data) {
      state.illnessId = data;
    },
    setActiveIndex(state, data) {
      state.activeIndex = data;
    },
    setUid(state, data) {
      state.uid = data;
    },
    setCommitList(state, data) {
      state.commitList = data;
    },
    setDownloadImg(state, data) {
      state.downloadImg = data;
    },
    setSource(state, data) {
      state.source = data;
    },
    setRelation(state, data) {
      state.relation = data;
    },
    setBeginSource(state, data) {
      state.beginSource = data;
    },
    setUidData(state, data) {
      state.uidData = data;
    },
    setPhone(state, data) {
      state.phone = data;
    },
  },
  getters: {
    targetId: (state) => state.targetId,
    targetList: (state) => state.targetList,
    illness: (state) => state.illness,
    illnessId: (state) => state.illnessId,
    activeIndex: (state) => state.activeIndex,
    uid: (state) => state.uid,
    commitList: (state) => state.commitList,
    downloadImg: (state) => state.downloadImg,
    source: (state) => state.source,
    relation: (state) => state.relation,
    beginSource: (state) => state.beginSource,
    uidData: (state) => state.uidData,
    phone: (state) => state.phone,
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          targetId: val.targetId,
          targetList: val.targetList,
          illness: val.illness,
          illnessId: val.illnessId,
          activeIndex: val.activeIndex,
          uid: val.uid,
          commitList: val.commitList,
          downloadImg: val.downloadImg,
          source: val.source,
          relation: val.relation,
          beginSource: val.beginSource,
          uidData: val.uidData,
          phone: val.phone,
        };
      },
    }),
  ],
});
